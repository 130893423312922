<template>
  <div>
    <div class="breadcrumb">
      <el-breadcrumb>
        <el-breadcrumb-item>{{route_name}}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="router_container">
      <div class="router_view">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      route_name: ""
    };
  },
  watch: {
    $route: {
      handler: function(route) {
        // this.route_name = route.name;
        this.route_name = route.query.isAdd == 2 ? '运营管理 / 编辑优惠券' :  route.query.isAdd == 'check' ? '运营管理 / 查看优惠券' : !route.query.isAdd ? '运营管理 / 优惠券管理':'运营管理 / 添加优惠券'
      },
      immediate: true
    }
  }
};
</script>
